import React from "react";
import Layout from "../layouts";
import { graphql } from "gatsby";

import CareersHero from "../components/careers/careersHero";
import CareersPerks from "../components/careers/careersPerks";
import CareersOpenings from "../components/careers/careersOpenings";
import CareersGRP from "../components/careers/careersGRP";
import SEO from "../components/seo";

const Careers = ({ data }) => {
  const { nodes } = data.allContentfulCareersPage;
  const hero = nodes.filter(node => node.sectionTitle === "01_Careers - Hero");
  const perks = nodes.filter(node => node.sectionTitle === "02_Careers - Perks");
  const jobs = nodes.filter(node => node.sectionTitle === "03_Careers - Job Openings");
  const grp = nodes.filter(node => node.sectionTitle === "04_Careers - Growth Rotational Program");
  
  return (
    <Layout>
      <SEO
        description="FFG is a place where learning and productive intellectual challenges foster personal and professional growth for all our employees."
        title="Careers | Full Funnel Growth"
      />
      <CareersHero content={hero}/>
      <CareersPerks content={perks}/>
      <CareersOpenings content={jobs}/>
      <CareersGRP content={grp}/>
    </Layout>
    
  );
};

export default Careers;

export const query = graphql`
query CareerQuery {
  allContentfulCareersPage {
    nodes {
      sectionTitle
      imageAssets {
        gatsbyImageData(
          quality: 100,
          placeholder: BLURRED
        )
        title
        url
      }
      bodyText {
        text
      }
      orangeBackgroundRectangle{
        title
        gatsbyImageData(
          quality: 100,
          placeholder: BLURRED
        )
      }
      cardComponents {
        references {
          cardTitle
          cardImage {
            gatsbyImageData(
              quality: 100,
              placeholder: BLURRED
            )
            title
            url
          }
          cardUrl
        }
      }
    }
  }
}
`;
