import React from "react";
import styled from "styled-components";

const MainArea = styled.div`
  position: absolute;
  top:0;
  left:0;
  width:100%;
  height: 100%;
  z-index: -1;

  .circles{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: ${({ show }) => show === undefined ? "visible" : "hidden"};

    /* & div:hover{
      transform: scale(1.05);
    } */

    & div{
      position: absolute;
      display: block;
      opacity: 0.2;
      border-radius: 50%;
      animation: animate .5s ease-in 1;
      /* transition: transform 500ms ease; */
      @keyframes animate{
        0%{
          opacity: 0;
        }
        100%{
          opacity: 0.2;
        }
      }

      &:nth-child(n){
        border: ${({ th }) => `${th[0]}px`} solid ${({ color }) => color === undefined ? "var(--orange)" : "rgba(255,255,255,0.5)"};
        left: ${({ x }) => `${x[0]}%`};
        top: ${({ y }) => `${y[0]}%`};
        width: ${({ size }) => `${size[0]}px`};
        height: ${({ size }) => `${size[0]}px`};
      }
      &:nth-child(2){
        border: ${({ th }) => `${th[1]}px`} solid ${({ color }) => color === undefined ? "var(--orange)" : "rgba(255,255,255,0.5)"};
        left: ${({ x }) => `${x[1]}%`};
        top: ${({ y }) => `${y[1]}%`};
        width: ${({ size }) => `${size[1]}px`};
        height: ${({ size }) => `${size[1]}px`};
      }
      &:nth-child(3){
        border: ${({ th }) => `${th[2]}px`} solid ${({ color }) => color === undefined ? "var(--orange)" : "rgba(255,255,255,0.5)"};
        left: ${({ x }) => `${x[2]}%`};
        top: ${({ y }) => `${y[2]}%`};
        width: ${({ size }) => `${size[2]}px`};
        height: ${({ size }) => `${size[2]}px`};
        @media only screen and (max-width: 500px){
          display: none;
        }
      }
      &:nth-child(4){
        border: ${({ th }) => `${th[3]}px`} solid ${({ color }) => color === undefined ? "var(--orange)" : "rgba(255,255,255,0.5)"};
        left: ${({ x }) => `${x[3]}%`};
        top: ${({ y }) => `${y[3]}%`};
        width: ${({ size }) => `${size[3]}px`};
        height: ${({ size }) => `${size[3]}px`};
        @media only screen and (max-width: 1024px){
          display: none;
        }
      }
      &:nth-child(5){
        border: ${({ th }) => `${th[4]}px`} solid ${({ color }) => color === undefined ? "var(--orange)" : "rgba(255,255,255,0.5)"};
        left: ${({ x }) => `${x[4]}%`};
        top: ${({ y }) => `${y[4]}%`};
        width: ${({ size }) => `${size[4]}px`};
        height: ${({ size }) => `${size[4]}px`};

        @media only screen and (max-width: 1024px){
          display: none;
        }
      }
    }
  }

`;

const Background = ({ color, show }) => {
  let circle_thickness = [];
  let circle_sizes = [];
  let circle_x = [];
  let circle_y = [];

  for (let i = 0; i < 5; i++) {
    var th = Math.ceil(5 + (15 * Math.random()));
    circle_thickness.push(th);
    var rr = Math.ceil((200 * Math.random()) + 5);
    circle_sizes.push(rr);
    var left = Math.ceil(100 * Math.random());
    circle_x.push(left);
    var top = Math.ceil(100 * Math.random());
    circle_y.push(top); 
  }

  return (
    <MainArea color={color} show={show} size={circle_sizes} th={circle_thickness} x={circle_x} y={circle_y}>
      <div className="circles">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </MainArea>
  );
};

export default Background;