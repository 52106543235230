import { GatsbyImage, getImage } from "gatsby-plugin-image";
import React from "react";
import styled from "styled-components";

const CareersPerksWrapper = styled.div`
  max-width: 1440px;
  background-color: var(--blue);
  display: flex;
  justify-content: space-evenly;
  align-items: baseline;
  width:100%;
  padding: 1rem 0;

  .perk__container{
    display:flex;
    flex-direction: column;
    align-items: center;
  }

  .perk__image{
    @media only screen and (max-width: 768px){
      width: 75%;
    }
  }

  .perk__title{
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 32px;
    line-height: 129.9%;
    text-align: center;
    letter-spacing: -0.005em;
    color: #ffffff;

    @media only screen and (max-width: 1250px){
      font-size: 28px;
      margin-top: 1rem;
      margin-bottom: 2rem;
    }

    @media only screen and (max-width: 1024px){
      font-size: 24px;
      margin-top: 1rem;
      margin-bottom: 2rem;
    }

    @media only screen and (max-width: 768px){
      font-size: 25px;
      margin-top: 1rem;
      margin-bottom: 2rem;
    }

    @media only screen and (max-width: 500px){
      font-size: 20px;
      width: 100%;
    }
  }

  @media only screen and (max-width: 768px){
    display: grid;
    place-items: center;
    grid-template-columns: repeat(2, 1fr);
    align-items: baseline;

    .perk__container:first-child{
      grid-column: 1/3;
    }

    .perk__container:first-child .perk__image{
      width: 66%;
    }
  }


  @media only screen and (min-width: 1920px){
    position: relative;
    left:50%;
    transform: translateX(-50%);
  }

`;

const CareersPerks = ({ content }) => {
  const { cardComponents } = content[0];
  return (
    <CareersPerksWrapper>
      {cardComponents.references.map((perk, index) => {
        const { cardImage, cardTitle } = perk;
        return (
          <div className="perk__container" key={index}>
            <GatsbyImage alt={cardTitle} className='perk__image'image={getImage(cardImage.gatsbyImageData)}/>
            <p className='perk__title'>{cardTitle}</p>
          </div>
        );
      })}
    </CareersPerksWrapper>
  );
};

export default CareersPerks;