import React from "react";
import styled from "styled-components";
import { AiFillCaretRight } from "react-icons/ai";

const CareersOpeningsWrapper = styled.div`
  max-width: 1440px;
  display:flex;
  flex-direction: column;
  width:100%;
  align-items: flex-start;
  margin-bottom: 7rem;

  .section__title, .section__title__subheading{
    margin-top: 7rem;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-size: 45px;
    line-height: 129.9%;
    color: var(--blue);
    letter-spacing: -0.005em;
    width:100%;
    text-align: left;
    padding-left: 5rem;

    @media only screen and (max-width: 1250px){
      font-size: 40px;
    }

    @media only screen and (max-width: 1024px){
      font-size: 35px;
    }

    @media only screen and (min-width: 501px) and (max-width: 768px){
      font-size: 50px;
      padding: 0;
      text-align: center;
      margin-top: 5rem;
    }

    @media only screen and (max-width: 500px){
      font-size: 30px;
      padding: 0;
      text-align: center;
      margin-top: 5rem;
    }
  }

  .section__title__subheading{
    margin-top: 1rem;
    text-align: center;
  }

  .careers_listing__container{
    display:flex;
    flex-direction: column;
    align-items: center;
    width:100%;
    padding: 0 5rem;

    @media only screen and (max-width: 768px){
      padding: 0 3rem;
    }

    @media only screen and (max-width: 500px){
      padding: 0 1rem;
    }
    
  }

  .job_button__container{
    width: 100%;
    margin-top: 2rem;
    margin-bottom: 2rem;
    box-shadow: 0px 9px 35px rgba(0, 0, 0, 0.25);
    border-radius: 3rem;

    @media only screen and (max-width: 768px){
      margin-left: 0;
      margin-top: 1.5rem;
      margin-bottom: 1.5rem;
    }
  }

  .job_button{
    max-width: none;
    border-radius: 1rem;
    width:100%;
    text-align: left;
    padding-left: 3rem;
    display:flex;
    align-items: center;
    justify-content: space-between;
    text-decoration: none;

    @media only screen and (min-width: 501px) and (max-width: 768px){
      padding-left: 1rem;
      padding-top: 2rem;
      padding-bottom: 2rem;
      border-radius: 3rem;
    }

    @media only screen and (max-width: 500px){
      padding-left: 1rem;
    }
  }

  .job_button:hover{
    background-color: var(--blue);
    border-color: var(--blue);
  }

  .job_button p{
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 36px;
    line-height: 129.9%;
    letter-spacing: -0.005em;
    color: #ffffff;
    margin-top: 1rem;
    margin-bottom: 1rem;

    @media only screen and (max-width: 1250px){
      font-size: 29px;
    }

    @media only screen and (max-width: 1025px){
      font-size: 22px;
    }

    @media only screen and (min-width: 501px) and (max-width: 768px){
      font-size: 22px;
      margin-left: 1rem;
    }

    @media only screen and (max-width: 500px){
      font-size: 15px;
    }
  }

  .rightArrow{
    color: #ffffff;
    font-size: 36px;

    @media only screen and (min-width: 501px) and (max-width: 768px){
      font-size: 25px;
      margin-right: 1rem;
    }

    @media only screen and (max-width: 500px){
      font-size: 20px;
      padding-right: 0.5rem;
    }
  }

  @media only screen and (max-width: 500px){
    margin-bottom: 3rem;
  }

  @media only screen and (min-width: 1920px){
    position: relative;
    left:50%;
    transform: translateX(-50%);
  }
`;

const CareersOpenings = ({ content }) => {
  const { bodyText, cardComponents } = content[0];
  return (
    <CareersOpeningsWrapper id="hiring">
      <h2 className="section__title">{bodyText[0].text}:</h2>
      {!cardComponents &&
        <h2 className="section__title__subheading">There are currently no job openings...</h2>
      }
      <div className="careers_listing__container">
        {cardComponents && cardComponents.references.map((job, index) => {
          const { cardTitle, cardUrl } = job;
          return (
            <div className="job_button__container" key={index}>
              <a className='hire_button job_button' href={cardUrl}>
                <p>{cardTitle}</p>
                <AiFillCaretRight className='rightArrow'/>
              </a>
            </div>
          );
        })}
      </div>
      
    </CareersOpeningsWrapper>
  );
};

export default CareersOpenings;